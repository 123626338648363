<template>
  <v-container fluid class="pa-0" >
    <!-- PUBLISH ALERT -->
    <v-alert type="warning" prominent :value="view.admin && !division.showTeams" transition="scale-transition">
      <v-row align="center">
        <v-col class="grow">
          <strong class="black--text">{{division.name}} teams are not published.</strong>
        </v-col>
        <v-col class="shrink">
          <v-btn
            @click.stop="loading = true; division.patch({showTeams: true})"
            :loading="loading"
          >publish</v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <v-row>
      <v-col cols="12">
        <v-container fluid class="pt-0">
          <!-- Search bar -->
          <v-row dense justify="end">
            <v-col cols="12" class="d-flex justify-end" v-if="!division.isCS && (division.numOfPlayers > 2 || division.useTeamNames || division.isNcaa)">
              <v-checkbox
                label="Show Players"
                v-model="showPlayers"
                dense
                hide-details
                class="ma-0 shrink"
                color="success"
              ></v-checkbox>
            </v-col>
            <v-col cols="12">
              <v-text-field
                append-icon="fas fa-search"
                label="Search"
                hide-details
                v-model="search"
                :disabled="seeding"
                color="color3"
                clearable
                solo-inverted
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- ADMIN BUTTONS -->
          <v-row dense v-if="view.admin && division.teams.length > 0 && !division.isCS">
            <!-- SEED BUTTON -->
            <v-col cols="6">
              <v-btn
                v-if="display !== 'public' && !locked && !waitlist"
                text
                color="color3"
                :disabled="seeding"
                @click.stop="seeding=true"
              >
                <v-icon class="mr-4">fas fa-list-ol</v-icon>
                {{division.isFullySeeded ? 'Re-' : ''}}Seed
              </v-btn>
            </v-col>
            <!-- RANDOM SEED & COLUMN -->
            <v-col class="text-right" cols="6" >
              <v-btn
                text
                v-if="seeding"
                color="color3"
                @click="assignRandomSeeds">
                <v-icon class="mr-4">fas fa-random</v-icon>
                <span>Go Random</span>
              </v-btn>
              <v-fab-transition>
                <column-dialog v-if="adminDisplay" :columns="columns" :division="division"></column-dialog>
              </v-fab-transition>
              <ratings-loader
                :btnClass="'ml-2'"
                :username="tournament.organization.username"
              ></ratings-loader>
            </v-col>
            <!-- VIEW -->
            <v-col cols="12" class="text-right">
              <v-btn
                color="color3"
                class="mr-1"
                text x-small
                v-if="!seeding"
                @click.stop="adminDisplay = !adminDisplay"
              >
                You are currently in {{adminDisplay ? 'Admin View' : 'Public View'}}
              </v-btn>
            </v-col>
          </v-row>
          <!-- TEAM LIST DISPLAY -->
          <v-row dense>
            <v-col cols="12">
              <!-- COACHES -->
              <template v-if="division.isCoaches">
                <coach-display
                  :division="division"
                  :search="search"
                  :admin="display !== 'public'"
                  :columns="publicColumns"
                ></coach-display>
              </template>
              <!-- PUBLIC VIEW -->
              <template v-else-if="display === 'public'">
                <team-list-display-public
                  v-if="!waitlist"
                  :division="division"
                  :search="search"
                  :showPoints="tournament.isTournament && tournament.sanctioningBody"
                  :columns="publicColumns"
                  :showPlayers="showPlayers"
                >
                </team-list-display-public>
                <waitlist-display-public
                  v-else
                  :division="division"
                  :search="search"
                  :columns="publicColumns"
                  :showPlayers="showPlayers"
                >
                </waitlist-display-public>
              </template>
              <!-- ADMIN VIEW -->
              <template v-else>
                <!-- WAITLIST -->
                <waitlist-display-admin v-if="waitlist"
                  :division="division"
                  :search="search"
                  :columns="columns.filter(f => f.show)"
                  :tournamentId="tournamentId"
                  :showPlayers="showPlayers"
                >
                </waitlist-display-admin>
                <!-- TEAMS -->
                <team-list-display-admin
                  v-else-if="display === 'admin'"
                  :division="division"
                  :showDeleted="showDeleted"
                  :search="search"
                  :columns="columns.filter(f => f.show)"
                  :showPlayers="showPlayers"
                >
                </team-list-display-admin>
                <!-- SEEDING DISPLAY -->
                <team-seeding
                  ref="seedTable"
                  v-else
                  :columns="columns.filter(f => f.show)"
                  @cancel-click="seeding = false"
                  @seeding-saved="onSeedingSaved"
                  :showPlayers="showPlayers"
                >
                </team-seeding>
              </template>
            </v-col>
          </v-row>
          <!-- ADMIN BUTTONS -->
          <v-row dense v-if="view.admin">
            <v-col cols="12" class="text-right">
              <points-dialog v-if="view.admin" :division="division"></points-dialog>
              <get-social v-if="view.admin" :players="division.activePlayers"></get-social>
              <unseed-dialog :divisionId="divisionId" v-if="false"></unseed-dialog>
              <data-exporter v-if="view.admin"></data-exporter>
            </v-col>
            <v-col cols="12" class="text-right" v-if="view.admin">
              <v-btn color="color3" small text @click.stop="$refs.bulkMoveDrawer.open()">Bulk Move/Copy</v-btn>
            </v-col>
            <bulk-move-drawer :division="division" ref="bulkMoveDrawer" :waitlist="waitlist"></bulk-move-drawer>
            <v-col cols="12" v-if="tournament.hasJuniors">
              <tournament-rating-summary-dialog
                :tournamentId="tournamentId"
                :tournament="tournament"
              ></tournament-rating-summary-dialog>

            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-snackbar
      vertical
      :timeout="3333"
      v-model="snackbar"
      bottom
      color="success"
    >
      {{snackbarMessage}}
      <v-btn text color="white" @click.stop="snackbar=false">close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import RouteMixin from '@/Mixins/RouteMixin'
import { mapGetters } from 'vuex'
const CoachDisplay = () => import('@/components/Tournament/Division/Teams/CoachDisplay.vue')
const TeamListDisplayPublic = () => import('@/components/Tournament/Division/Teams/TeamListDisplayPublic.vue')
const TeamListDisplayAdmin = () => import('@/components/Tournament/Division/Teams/TeamListDisplayAdmin.vue')
const WaitlistDisplayPublic = () => import('@/components/Tournament/Division/Teams/WaitlistDisplayPublic.vue')
const WaitlistDisplayAdmin = () => import('@/components/Tournament/Division/Teams/WaitlistDisplay.vue')
const TeamSeeding = () => import('@/components/Tournament/Division/Teams/TeamSeeding.vue')
const ColumnDialog = () => import('@/components/Tournament/Division/Teams/ColumnDialog.vue')
const DataExporter = () => import('@/components/Tournament/ExportDialog.vue')
const UnseedDialog = () => import('@/components/Tournament/Division/Teams/Unseed.vue')
const GetSocial = () => import('@/components/Player/GetSocial')
const PointsDialog = () => import('@/components/Tournament/Division/Teams/PointsDialog')
const RatingsLoader = () => import('@/components/Ratings/RatingsLoader')
const BulkMoveDrawer = () => import('@/components/Tournament/Division/BulkMoveDrawer')
const TournamentRatingSummaryDialog = () => import('@/components/Ratings/TournamentRatingSummaryDialog')

export default {
  props: ['startSeeding', 'waitlist'],
  mixins: [RouteMixin],
  data () {
    return {
      search: null,
      showDeleted: false,
      seeding: false,
      snackbar: false,
      snackbarMessage: null,
      loading: false,
      columns: [],
      adminDisplay: true,
      showPlayers: false
    }
  },
  computed: {
    ...mapGetters(['tournament', 'getDivision', 'view', 'getDivisionJProp']),
    locked () {
      return this.tournament.locked || this.division.complete
    },
    division () {
      return this.getDivision(this.divisionId)
    },
    teams () {
      return this.showDeleted ? this.division.teams : this.division.teams.filter(f => !f.isDeleted)
    },
    finished () {
      return this.teams.filter(f => f.finish)
    },
    fullySeeded () {
      return this.division && this.division.isFullySeeded
    },
    unSeededTeams () {
      return this.division && this.division.unSeededTeams
    },
    publicColumns () {
      return this.columns && this.columns.filter(f => this.getDivisionJProp(this.division.id, `public-${f.value}`))
    },
    display () {
      if (this.view.public) return 'public'
      // admin view
      if (this.seeding) return 'admin-seeding'
      if (this.finished.length && !this.adminDisplay) return 'public'
      return this.adminDisplay ? 'admin' : 'public'
    }
  },
  methods: {
    assignRandomSeeds () {
      this.$refs.seedTable.assignRandomSeeds()
    },
    onSeedingSaved () {
      this.seeding = false
      this.snackbarMessage = `${this.division.name} seeding has been saved.`
      this.snackbar = true
    },
    initColumns () {
      this.columns = [
        { header: 'Captain', show: this.division.props.includes('useCaptains'), value: 'captain', perPlayer: false },
        { header: 'City State', show: this.division.repField === 'cityState', value: 'cityState', perPlayer: true },
        { header: 'Roster Status', show: this.division.teamReg, value: 'rosterStatus' },
        { header: 'Club', show: !this.division.teamReg && this.division.repField === 'club', value: 'club', perPlayer: true },
        { header: 'School', show: this.division.repField === 'hs', value: 'highSchool', perPlayer: true },
        { header: 'Grad Year', show: false, value: 'gradYear', perPlayer: true },
        // { header: 'College', show: false, value: 'committedSchool', perPlayer: true },
        { header: 'AAU Points', show: this.division.isAAU, value: 'aauPoints', isPoints: true },
        { header: 'USAVP Points', show: (this.division.isAVP || this.division.isUSAV) && !this.division.isCoed, value: 'avpaPoints', isPoints: true },
        { header: 'USAVP Coed Points', show: (this.division.isAVP || this.division.isUSAV) && this.division.isCoed, value: 'avpaCPoints', isPoints: true },
        { header: 'BVNE Points', show: this.division.isBVNE, value: 'bvnePoints', isPoints: true },
        { header: 'p1440 Points', show: this.division.isP1440, value: 'p1440Points', isPoints: true },
        { header: 'Registration Date', show: false, value: 'unix' },
        { header: 'Primary State', show: false, value: 'primaryState' }
      ]
      if (this.tournament.props.includes('fields')) this.columns.unshift({ header: 'Field', show: true, value: 'field' })
      if (this.division.isNcaa || this.tournament.props.includes('truVolley')) this.columns.unshift({ header: 'TruVolley', show: this.division.isBeach, value: 'movr', perPlayer: true })
      if (this.tournament.props.includes('truVolley')) this.columns.unshift({ header: 'TruVolley Grass', show: this.division.isGrass, value: 'movrG', perPlayer: true })
    }
  },
  components: {
    TeamListDisplayPublic,
    TeamListDisplayAdmin,
    WaitlistDisplayPublic,
    WaitlistDisplayAdmin,
    TeamSeeding,
    ColumnDialog,
    DataExporter,
    CoachDisplay,
    UnseedDialog,
    GetSocial,
    PointsDialog,
    RatingsLoader,
    BulkMoveDrawer,
    TournamentRatingSummaryDialog
  },
  watch: {
    waitlist: function (n, o) {
      if (n !== o) this.initColumns()
    }
  },
  mounted () {
    this.initColumns()
    if (this.startSeeding || this.$route.params.seed) {
      this.seeding = true
    }
    if (this.division.isNcaa && !this.division.isDuals) this.showPlayers = true
  }
}
</script>

<style scoped>
  .deleted {
    text-decoration: line-through
  }
</style>
